import React from 'react';
import './Polygon.scss';
import './mobile/index.scss';

type OwnProps = {
    border?: any;
    boxShadow?: string | any;
    cornerPosition?: 'rightTop' | 'rightBottom';
    cornerRadius?: number;
    bgColor?: string;
    children?: any;
    style?: any;
    className?: string;
    innerClassName?: string;
    onClick?: (...args: any[]) => any;
};
type Props = OwnProps;
const Polygon = (props: Props) => {
  const {
    border = {
      width: null,
      color: null,
      radius: 0,
    }, boxShadow = {
      x: 0,
      y: 0,
      blurRadius: null,
      color: null,
    }, // {} || 'paper'
    cornerPosition = 'rightTop', cornerRadius = 0, bgColor = '#fff', children = null, style = {}, className = '', innerClassName = '', onClick = () => { },
  } = props;
  const getBorder = () => {
    if (!border.width) return {};
    return { filter: `drop-shadow(${border.width}px 0 0 ${border.color})`, borderRight: '1px solid transparent' };
  };
  const getShadow = () => {
    if (!boxShadow.blurRadius && typeof boxShadow !== 'string') return {};
    // Paper box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25)
    if (typeof boxShadow === 'string' && boxShadow === 'paper') return { filter: 'drop-shadow(0 1px 3px rgba(0,0,0,0.25))', WebkitFilter: 'drop-shadow(0 1px 3px rgba(0,0,0,0.25))' };
    return { filter: `drop-shadow(${boxShadow.color} ${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blurRadius}px)` };
  };
  const getPolygonAndBorder = () => {
    const clipPath = cornerPosition === 'rightBottom'
      ? `polygon(0 0, 100% 0, 100% calc(100% - ${cornerRadius}px), calc(100% - ${cornerRadius}px) 100%, 0 100%)`
      : `polygon(0 0, calc(100% - ${cornerRadius}px) 0, 100% ${cornerRadius}px, 100% 100%, 0 100%)`;
    let styles = { clipPath, WebkitClipPath: clipPath };
    if (bgColor) (styles as any).backgroundColor = bgColor;
    // @ts-ignore
    if (border.width) styles = { ...styles, border: `${border.width}px solid ${border.color}` };
      // @ts-ignore
    if (border.radius) styles = { ...styles, borderRadius: border.radius };
    return styles;
  };
  return (
    <div className={`Polygon ${className}`} style={style} onClick={onClick}>
      <div className="PolygonShadow" style={getShadow()}>
        <div className="PolygonBorder" style={getBorder()}>
          <div className={`PolygonGeometryAndBorder ${innerClassName}`} style={getPolygonAndBorder()}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
Polygon.defaultProps = {
  border: {
    width: null,
    color: null,
    radius: 0,
  },
  boxShadow: {
    x: 0,
    y: 0,
    blurRadius: null,
    color: null,
  },
  cornerPosition: 'rightTop',
  cornerRadius: 0,
  bgColor: '#fff',
  children: null,
  style: {},
  className: '',
  innerClassName: '',
  onClick: () => { },
};
export default Polygon;
