import React, { Component } from 'react';
import './PushNotification.scss';
import './mobile/index.scss';
import { Trans } from '@i18n';
import Router from 'next/router';
import { Btn } from '../index';

class PushNotification extends Component<any, any> {
  links: any;

  text: any;

  title: any;

  type: any;

  constructor(props: any) {
    super(props);
    this.type = props.type;
    this.text = props.text;
    this.title = props.title;
    this.links = props.links;
  }

  render() {
    return (
      <div className="push_notification">
        <div className="push_notification_container">
          <div
            className="push_notification_color-block"
            style={this.type === 'error' ? { backgroundColor: '#E1595A' } : { backgroundColor: '#0FB580' }}
          />
          <div className="push_notification_text">
            <h1 className="push_notification_h1">
              { (() => {
                if (this.title !== 'undefined' && this.title !== 'null') {
                  return this.title;
                }
                if (this.type === 'error') {
                  return <Trans>Внимание</Trans>;
                }
                return '';
              })()}
            </h1>
            <p className="push_notification_p">
              <Trans>{this.text}</Trans>
            </p>
            {(this.links !== undefined && this.links !== null) && (
            <div className="push_notification__links">
              {this.links.map((link: any) => (
                <Btn className="push_notification__link" key={link.text} onClick={() => Router.push(link.href, link.as)}>
                  <Trans>{link.text}</Trans>
                </Btn>
              ))}
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default PushNotification;
