import React, { forwardRef } from 'react';
import './Btn.scss';
import './mobile/index.scss';
import { Polygon } from '../index';
import PropTypes from 'prop-types';

type Props = {
    block?: boolean;
    flex?: boolean;
    loading?: any;
    disabled?: boolean;
    nowrap?: boolean;
    justifyContent?: 'normal' | 'space-between' | 'center';
    alignItems?: 'normal' | 'center';
    color?: string
    size?: string;
    component?: string;
    variant?: 'normal' | 'outlined' | 'oval' | 'oval-outlined';
    border?: any,
    children?: React.ReactChild | React.ReactNode,
    onClick?: (e?: any) => void,
    type?: string,
    className?: string,
    cornerRadius?: number,
    href?: string,
    target?: any,
    style?: any
};
const Btn = forwardRef<any, Props>((props, ref) => {
  const {
    children, block = false, flex = false, justifyContent = 'normal', alignItems = 'normal', disabled = false, color = 'blue', size = 'md', component = 'a', variant = 'normal', loading = false, nowrap = false, border = {}, cornerRadius = 12,
  } = props;
  const Component = component;
  const elementProps = { ...props };
  const getElementProps = (elProps: any) => {
    const newProps = { ...elProps };
    ['theme', 'children', 'block', 'flex', 'justifyContent', 'alignItems', 'disabled', 'color', 'size', 'component', 'variant', 'loading', 'nowrap', 'border'].forEach((item) => {
      if (newProps.hasOwnProperty(item)) {
        delete newProps[item];
      }
    });
    return newProps;
  };
  const getClasses = () => {
    const classes = ['Btn'];
    if (block || flex) classes.push('Btn-block');
    return classes.join(' ');
  };
  const getBgColor = () => {
    let btnColor = 'Btn-blue';
    if (color === 'blue') btnColor = 'Btn-blue';
    if (color === 'blue-fb') btnColor = 'Btn-blue-fb';
    if (color === 'white') btnColor = 'Btn-white';
    if (color === 'yellow') btnColor = 'Btn-yellow';
    if (color === 'dark-yellow') btnColor = 'Btn-dark-yellow';
    if (color === 'gray') btnColor = 'Btn-gray';
    if (color === 'red') btnColor = 'Btn-red';
    if (color === 'transparent') btnColor = 'Btn-transparent';
    return btnColor;
  };
  const getVariant = () => {
    let btnVariant = null;
    if (variant === 'outlined') btnVariant = 'Btn-outlined';
    if (variant === 'oval') btnVariant = 'Btn-oval';
    if (variant === 'oval-outlined') btnVariant = 'Btn-oval Btn-oval-transparent';

    return btnVariant;
  };
  const getCustomBorder = () => {
    let btnCustom = null;
    if (border.width) btnCustom = 'Btn-custom-border';
    return btnCustom;
  };
  const getBtnSize = () => {
    let btnSize = 'md';
    if (size === 'md') btnSize = 'Btn-md';
    if (size === 'lg') btnSize = 'Btn-lg';
    if (size === 'sm') btnSize = 'Btn-sm';
    return btnSize;
  };
  const getInnerClasses = () => {
    const classes = ['Btn-inner', getBgColor(), getBtnSize(), getVariant(), getCustomBorder()];
    if (flex) classes.push('Btn-flex');
    if (nowrap) classes.push('Btn-nowrap');
    if (disabled) classes.push('Btn-disabled');
    if (justifyContent === 'space-between') classes.push('Btn-jc-between');
    if (justifyContent === 'center') classes.push('Btn-jc-center');
    if (alignItems === 'center') classes.push('Btn-ai-center');
    return classes.join(' ');
  };
  const getBtnBorder = () => {
    let brColor = '#C9C9C9';
    if (color === 'blue') brColor = '#03349d';
    if (color === 'white') brColor = '#ffffff';
    if (color === 'yellow') brColor = '#ffe27a';
    if (color === 'dark-yellow') brColor = '#F0B90B';
    if (color === 'gray') brColor = '#C9C9C9';
    if (border.width) {
      return { width: border.width || 2, color: border.color || '#ffffff' };
    }
    if (variant === 'outlined') {
      return { width: 2, color: brColor };
    }
    return {};
  };
  (elementProps as any).className = `${getClasses()}${(elementProps as any).className ? ` ${(elementProps as any).className}` : ''}`;
  const dotsColor = (variant === 'outlined' || color === 'white') ? { backgroundColor: '#b0b0b0' } : { backgroundColor: '#fff' };
  return (
    <>
      <Component ref={ref} {...getElementProps(elementProps)} style={loading ? { pointerEvents: 'none', opacity: '0.5' } : {}}>
        <Polygon
          bgColor=""
          innerClassName={getInnerClasses()}
          border={{ radius: ['oval-outlined', 'oval'].includes(variant) ? 28 : 4, ...getBtnBorder() }}
          cornerRadius={['oval-outlined', 'oval'].includes(variant) ? 0 : cornerRadius}>
          {!loading ? children : <div className="loading-block__text">{children}</div>}{loading ? (
            <div className="section-subscribe__loadin-block">
              <div className="loading_screen">
                <div className="loading_screen_background" />
                <div className="loading_screen_logo" />
                <div className="loading_screen_bars">
                  <div className="dot1 loader-dot" style={dotsColor} />
                  <div className="dot2 loader-dot" style={dotsColor} />
                  <div className="dot3 loader-dot" style={dotsColor} />
                </div>
              </div>
            </div>
          ) : null}
        </Polygon>
      </Component>
    </>
  );
});

Btn.propTypes = {
  block: PropTypes.bool,
  flex: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  nowrap: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['normal', 'space-between', 'center']),
  alignItems: PropTypes.oneOf(['normal', 'center']),
  color: PropTypes.oneOf(['blue', 'blue-fb', 'yellow', 'dark-yellow', 'gray', 'white', 'red']),
  size: PropTypes.oneOf(['md', 'lg']),
  component: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'outlined', 'oval', 'oval-outlined']),
  border: PropTypes.object
};
Btn.defaultProps = {
  block: false,
  flex: false,
  loading: false,
  disabled: false,
  nowrap: false,
  justifyContent: 'normal',
  alignItems: 'normal',
  color: 'blue',
  size: 'md',
  component: 'a',
  variant: 'normal',
  border: {},
};
export default Btn;
